<template>
    <ion-page>
        <ion-toolbar
            style="--background: #03a9f4; color: white; --min-height: 36px"
            class="custom-toolbar"
        >
            <div class="row align-center">
                <slot name="before">
                    <div class="shrink">
                        <DxButton
                            type="default"
                            icon="mdi mdi-chevron-left"
                            class="ml-1"
                            @click="$router.go(-1)"
                        />
                    </div>
                </slot>
                <slot name="center">
                    <div class="grow text-xs-center">DANH SÁCH VÉ</div>
                </slot>
                <slot name="after">
                    <div class="shrink">
                        <DxButton
                            icon="mdi mdi-filter-variant"
                            type="default"
                            style="opacity: 0"
                        />
                    </div>
                </slot>
            </div>
        </ion-toolbar>

        <ion-content :scroll-events="false">
            <div class="row px-2">
                <div class="xs12 danh-sach-chuyen-di mt-2">
                    <DanhSachVeVue />
                </div>
            </div>
        </ion-content>
    </ion-page>
</template>

<script>
import {
    IonContent,
    IonPage,
    IonToolbar,
    onIonViewWillEnter,
    onIonViewWillLeave,
} from "@ionic/vue";
import { DxButton } from "devextreme-vue";
import DanhSachVeVue from "./components/DanhSachVe.vue";
export default {
    components: {
        IonContent,
        IonPage,
        IonToolbar,
        DxButton,
        DanhSachVeVue,
    },
    data() {
        return {};
    },
    methods: {},
    created() {},
};
</script>

<style></style>
